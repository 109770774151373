body {
  background-color: #c7c7c7;
  font-family: Karla, sans-serif;
  color: #000;
  font-size: 14px;
  line-height: 1.4;
  letter-spacing: -0.25px;
}

h1 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 36px;
  font-weight: 700;
}

h2 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 1.2;
  font-weight: 700;
}

h3 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 13px;
  line-height: 1.4;
  font-weight: 700;
}

p {
  margin-bottom: 10px;
}

a {
  color: #000;
  text-decoration: underline;
}

.main {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 768px;
  margin-right: auto;
  margin-left: auto;
  padding: 64px 16px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.procurement-search {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 768px;
  padding: 48px 24px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  background-color: #fff;
  box-shadow: 1px 1px 15px 4px rgba(0, 0, 0, 0.12);
}

.body {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.body.receipt-mode {
  font-family: VT323, sans-serif;
  font-size: 16px;
  font-weight: 400;
}

.filters {
  position: relative;
  display: -ms-grid;
  display: grid;
  margin-bottom: 10px;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 0px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.filtered-list {
  position: relative;
  z-index: 5;
  overflow: scroll;
  max-height: 550px;
  margin-top: 12px;
  margin-right: -12px;
  padding-right: 8px;
  padding-bottom: 6px;
}

.row {
  display: -ms-grid;
  display: grid;
  width: 100%;
  height: 44px;
  padding: 8px 36px 8px 12px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-areas: "table-column-1 table-column-2 table-column-3";
  -ms-grid-columns: 1fr 16px 1fr 16px 100px;
  grid-template-columns: 1fr 1fr 100px;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  cursor: pointer;
}

.row:hover {
  position: relative;
}

.row.head {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 5;
  height: auto;
  margin-bottom: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.04);
  background-color: #fff;
  box-shadow: 0 16px 16px -7px rgba(0, 0, 0, 0.08);
  color: #000;
  font-size: 13px;
  font-weight: 700;
  cursor: default;
}

.row.row-dropdown__toggle {
  position: relative;
  z-index: 1;
  color: rgba(0, 0, 0, 0.6);
  text-decoration: none;
}

.row.row-dropdown__toggle:hover {
  background-color: rgba(0, 0, 0, 0.08);
}

.header {
  display: -ms-grid;
  display: grid;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-columns: 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 16px;
  grid-template-areas: ".";
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto 16px auto 16px auto;
  grid-template-rows: auto auto auto;
  text-align: center;
}

.page-title {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: 'Fake receipt', sans-serif;
  line-height: 1;
  text-align: center;
}

.filter {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.filter.filter--1 {
  z-index: 15;
}

.filter.filter--1.disabled {
  opacity: 0.25;
}

.filter.filter--2 {
  z-index: 14;
}

.filter.filter--3 {
  z-index: 13;
}

.filter.filter--4 {
  z-index: 12;
}

.filter.filter--5 {
  z-index: 11;
}

.filter.filter--6 {
  z-index: 10;
}

.filter.filter--7 {
  z-index: 9;
}

.filter.filter--8 {
  z-index: 8;
}

.filter.disabled {
  opacity: 0.3;
}

.label {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-size: 13px;
  font-weight: 700;
}

.label.receipt-mode {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0px;
}

.label.label--regular {
  font-weight: 400;
}

.search__bar {
  width: 100%;
  height: 44px;
  margin-bottom: 0px;
  padding-right: 56px;
  padding-left: 12px;
  border: 1px solid transparent;
  background-color: rgba(0, 0, 0, 0.08);
  -webkit-transition: border-color 400ms cubic-bezier(.23, 1, .32, 1), box-shadow 400ms cubic-bezier(.23, 1, .32, 1);
  transition: border-color 400ms cubic-bezier(.23, 1, .32, 1), box-shadow 400ms cubic-bezier(.23, 1, .32, 1);
}

.search__bar:hover {
  border-color: #000;
  background-color: rgba(0, 0, 0, 0.12);
}

.search__bar:focus {
  border-color: #000;
}

.search__bar::-webkit-input-placeholder {
  color: #000;
  font-size: 14px;
}

.search__bar:-ms-input-placeholder {
  color: #000;
  font-size: 14px;
}

.search__bar::-ms-input-placeholder {
  color: #000;
  font-size: 14px;
}

.search__bar::placeholder {
  color: #000;
  font-size: 14px;
}

.search__bar.receipt-mode::-webkit-input-placeholder {
  font-size: 16px;
}

.search__bar.receipt-mode:-ms-input-placeholder {
  font-size: 16px;
}

.search__bar.receipt-mode::-ms-input-placeholder {
  font-size: 16px;
}

.search__bar.receipt-mode::placeholder {
  font-size: 16px;
}

.search__loading {
  position: absolute;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 70px;
  height: 44px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.search__loading.hidden {
  display: none;
}

.fa {
  position: relative;
  z-index: 1;
  width: 13px;
  text-align: center;
}

.search__loading_animation {
  width: 70px;
  height: 44px;
  padding: 14px;
}

.search-box {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  overflow: hidden;
}

.search__input {
  position: relative;
  z-index: 2;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 44px;
  margin-bottom: 0px;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.current-filters {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.current-filter {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 33px;
  margin-right: 4px;
  margin-bottom: 4px;
  padding-right: 12px;
  padding-left: 12px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #000;
  -webkit-transition: all 400ms cubic-bezier(.23, 1, .32, 1);
  transition: all 400ms cubic-bezier(.23, 1, .32, 1);
  color: #fff;
  line-height: 1;
  text-decoration: none;
  cursor: pointer;
}

.current-filter:hover {
  padding-bottom: 3px;
}

.current-filter.w--current {
  background-color: #408cff;
}

.current-filter__close {
  margin-left: 10px;
  color: #fff;
}

.search {
  position: relative;
  z-index: 2;
  display: -ms-grid;
  display: grid;
  margin-top: 6px;
  margin-bottom: 6px;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 6px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.search-deselect {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 999;
  display: none;
}

.current-filters__wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 6px;
  margin-right: -4px;
  margin-bottom: -4px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.filtered-list__header {
  display: none;
  margin-bottom: 12px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr auto;
  grid-template-columns: 1fr auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.section-title {
  margin-top: 0px;
  margin-bottom: 0px;
}

.row-title {
  margin-top: 0px;
  margin-bottom: 0px;
  color: #000;
}

.row-title.receipt-mode {
  font-size: 16px;
  font-weight: 400;
}

.row-body.row-body--small {
  font-size: 12px;
  line-height: 1.2;
}

.row-body.receipt-mode {
  color: #000;
  font-size: 16px;
  font-weight: 400;
}

.row-body.row-body--last {
  text-align: right;
}

.results {
  font-weight: 700;
}

.row-dropdown {
  position: relative;
  min-height: 44px;
  margin-bottom: 12px;
  border-bottom: 1px solid #000;
  color: rgba(0, 0, 0, 0.6);
}

.row-content {
  position: relative;
  z-index: 1;
  overflow: hidden;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  cursor: default;
}

.row-content__inner {
  padding-bottom: 4px;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 0px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.row-content__item {
  display: -ms-grid;
  display: grid;
  width: 100%;
  padding: 8px 12px 10px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-areas: "Input Value";
  -ms-grid-columns: 220px 16px 1fr;
  grid-template-columns: 220px 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  border-top: 1px dashed #a1a1a1;
}

.description-block {
  margin-bottom: -10px;
}

.description-block p {
  white-space: pre-wrap;
  -o-object-fit: fill;
  object-fit: fill;
}

.description-block.receipt-mode {
  font-size: 16px;
}

.row-icon-open {
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 24px;
  height: 44px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  color: #000;
}

.row-icon-close {
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: auto;
  display: none;
  width: 24px;
  height: 44px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  color: #000;
}

.search__add-filter {
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 44px;
  height: 44px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #000;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}

.search__add-filter:hover {
  padding-bottom: 3px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.16)), to(rgba(0, 0, 0, 0.16)));
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.16), rgba(0, 0, 0, 0.16));
}

.search__add-filter:focus {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.6)), to(rgba(0, 0, 0, 0.6)));
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
}

.row-bg {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: block;
  background-color: #fff;
  box-shadow: 0 10px 12px -8px rgba(0, 0, 0, 0.12);
}

.dropdown-list__inner {
  position: relative;
  max-height: 300px;
}

.dropdown-list__inner.scroll {
  margin: 8px 4px 8px 8px;
}

.dropdown-toggle {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 44px;
  padding: 0px 44px 0px 12px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid transparent;
  background-color: rgba(0, 0, 0, 0.08);
  -webkit-transition: all 400ms cubic-bezier(.23, 1, .32, 1);
  transition: all 400ms cubic-bezier(.23, 1, .32, 1);
}

.dropdown-toggle:hover {
  border-color: #000;
  background-color: rgba(0, 0, 0, 0.16);
  color: #000;
}

.dropdown-toggle:focus {
  border-color: #000;
}

.dropdown-toggle.dropdown-toggle--small {
  width: 160px;
  height: 33px;
  padding-right: 30px;
}

.dropdown-list__links {
  padding-right: 8px;
}

.dropdown-list__links.dropdown-list__links--no-scroll {
  padding-right: 0px;
}

.dropdown-list__links.dropdown-list__links--no-scroll.dropdown-list__links--small {
  width: 160px;
}

.dropdown-menu {
  width: 100%;
  margin-left: 0px;
}

.dropdown-menu.sorting-filter {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-size: 0.9em;
}

.dropdown-arrow {
  margin-right: 16px;
}

.dropdown-arrow.dropdown-arrow--small {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 12px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.dropdown-list {
  overflow: hidden;
  width: 100%;
  border-style: none solid solid;
  border-width: 1px;
  border-color: #000;
  background-color: #fff;
  box-shadow: 7px 7px 20px 0 rgba(0, 0, 0, 0.12);
}

.dropdown-list.w--open {
  top: 100%;
}

.dropdown-list.dropdownl-list--small {
  width: 160px;
}

.dropdown-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 40px;
  padding-right: 8px;
  padding-left: 8px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: padding 400ms cubic-bezier(.23, 1, .32, 1);
  transition: padding 400ms cubic-bezier(.23, 1, .32, 1);
  color: #000;
  text-decoration: none;
}

.dropdown-link:hover {
  background-color: rgba(0, 0, 0, 0.08);
  color: #000;
}

.dropdown-link.active {
  background-color: rgba(0, 0, 0, 0.08);
}

.dropdown-link.active:hover {
  background-color: rgba(0, 0, 0, 0.16);
  color: #000;
}

.dropdown-link.dropdown-link--facet {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr auto;
  grid-template-columns: 1fr auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.dropdown-link.dropdown-link--facet.active {
  padding-left: 6px;
  -ms-grid-columns: 30px 1fr auto;
  grid-template-columns: 30px 1fr auto;
  background-color: transparent;
}

.dropdown-link.dropdown-link--facet.active:hover {
  background-color: rgba(0, 0, 0, 0.08);
  color: #000;
}

.dropdown-link.receipt-mode {
  color: #000;
}

.no-filter {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 33px;
  margin-right: 4px;
  margin-bottom: 4px;
  padding-right: 12px;
  padding-left: 12px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.08);
  -webkit-transition: all 400ms cubic-bezier(.23, 1, .32, 1);
  transition: all 400ms cubic-bezier(.23, 1, .32, 1);
  color: rgba(0, 0, 0, 0.6);
  line-height: 1;
  text-decoration: none;
  cursor: default;
}

.no-filter.w--current {
  background-color: #408cff;
}

.button {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 44px;
  margin-top: 16px;
  padding-right: 12px;
  padding-left: 12px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}

.button:hover {
  background-color: rgba(0, 0, 0, 0.6);
}

.button.receipt-mode {
  background-color: #000;
}

.button.receipt-mode:hover {
  padding-bottom: 3px;
}

.filtering-info {
  position: relative;
  z-index: 6;
  display: -ms-grid;
  display: grid;
  padding-bottom: 16px;
  -webkit-box-align: end;
  -webkit-align-items: end;
  -ms-flex-align: end;
  align-items: end;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 160px;
  grid-template-columns: 1fr 160px;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.filter__sorting {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.advanced-search {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 6px 8px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid transparent;
  background-color: rgba(0, 0, 0, 0.08);
  color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
}

.advanced-search:hover {
  border-color: #000;
  background-color: rgba(0, 0, 0, 0.16);
  color: #000;
}

.advanced-search:focus {
  border-color: #000;
}

.advanced-filters {
  position: relative;
  z-index: 7;
  overflow: hidden;
  height: auto;
}

.advanced-filters.open {
  overflow: visible;
}

.advanced-search-icon--open {
  margin-left: 4px;
  color: #000;
}

.advanced-search-icon--close {
  margin-left: 4px;
  color: #000;
}

.filter-details {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.filter__showing {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 33px;
  padding: 4px 12px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.08);
  cursor: default;
}

.footer {
  display: -ms-grid;
  display: grid;
  margin-top: auto;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-columns: 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  font-family: 'Fake receipt', sans-serif;
  font-size: 12px;
}

.header-icon {
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 32px;
  height: 32px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #000;
  color: #fff;
}

.header-icon:hover {
  padding-bottom: 3px;
}

.filter__download {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 33px;
  padding-right: 12px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid transparent;
  background-color: rgba(0, 0, 0, 0.08);
  color: rgba(0, 0, 0, 0.6);
  text-decoration: none;
}

.filter__download:hover {
  border-color: #000;
  background-color: rgba(0, 0, 0, 0.16);
  color: #000;
}

.filter-details__inner {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 16px;
  -ms-grid-columns: -webkit-max-content -webkit-max-content -webkit-max-content;
  -ms-grid-columns: max-content max-content max-content;
  grid-template-columns: -webkit-max-content -webkit-max-content -webkit-max-content;
  grid-template-columns: max-content max-content max-content;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.filter__download_icon {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 32px;
  height: 32px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.dropdown-list__active {
  padding-right: 8px;
}

.dropdown-link__check {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 24px;
  height: 24px;
  margin-right: 6px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  background-color: #000;
  color: #fff;
}

.dropdown-link__text {
  padding-right: 16px;
}

.div-block {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 8px;
  bottom: 0%;
  z-index: 0;
  background-color: #d4d4d4;
}

.perforation-top {
  position: absolute;
  left: 0%;
  top: -10px;
  right: 0%;
  bottom: auto;
  z-index: 100;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 10px;
  background-image: url('../images/perforation.svg');
  background-position: 50% 50%;
  background-size: auto 10px;
  background-repeat: repeat-x;
  background-attachment: scroll;
}

.perforation-top.receipt-mode {
  display: block;
  background-repeat: repeat-x;
}

.perforation-bottom {
  position: absolute;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: -10px;
  z-index: 100;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 10px;
  background-image: url('../images/perforation-bottom.svg');
  background-position: 50% 50%;
  background-size: auto 10px;
  background-repeat: repeat-x;
  background-attachment: scroll;
}

.perforation-bottom.receipt-mode {
  display: block;
}

.divider {
  height: 1px;
  margin-top: 32px;
  margin-bottom: 32px;
  background-color: rgba(0, 0, 0, 0.08);
  background-image: none;
  background-size: auto;
  background-repeat: repeat;
}

.divider.receipt-mode {
  height: 13px;
  background-color: transparent;
  background-image: url('../images/asterix.svg');
  background-size: 34px 13px;
  background-repeat: repeat-x;
}

.site-description {
  margin-bottom: -10px;
  letter-spacing: 0px;
}

.view-buttons {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.mode-button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 44px;
  padding-right: 16px;
  padding-left: 16px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 2px;
  border-color: #000;
  background-color: #fff;
  color: #000;
  font-size: 16px;
  cursor: pointer;
}

.mode-button.mode-button--receipt {
  width: 50%;
  font-family: VT323, sans-serif;
  text-transform: uppercase;
}

.mode-button.active {
  border-color: #000;
  background-color: #000;
  color: #fff;
}

.mode-button.mode-button--normal {
  width: 50%;
  font-family: Karla, sans-serif;
}

.styles {
  display: none;
}

.donate-button {
  color: #000;
}

.page-header {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.filtered-list__no-data {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 24px 12px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.08);
}

.filtered-list__no-data.hidden {
  display: none;
}

.footer__logos {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.footer-logo {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.filter__share-link {
  display: none;
  height: 33px;
  padding-right: 12px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid transparent;
  background-color: rgba(0, 0, 0, 0.08);
  color: rgba(0, 0, 0, 0.6);
  text-decoration: none;
}

.filter__share-link:hover {
  border-color: #000;
  background-color: rgba(0, 0, 0, 0.16);
  color: #000;
}

.share-link--copied {
  display: none;
  color: #000;
}

.filter__share-link_icon {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 32px;
  height: 32px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.filtered-list__loading {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 64px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.image {
  width: 44px;
  height: 44px;
}

@media screen and (max-width: 991px) {
  .main {
    width: auto;
  }
  .filters {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 767px) {
  .main {
    width: auto;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .row-content__item {
    -ms-grid-columns: 180px 1fr;
    grid-template-columns: 180px 1fr;
  }
  .dropdown-toggle.dropdown-toggle--small {
    width: 100%;
  }
  .dropdown-list__links.dropdown-list__links--no-scroll.dropdown-list__links--small {
    width: auto;
  }
  .footer {
    grid-auto-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 24px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
    text-align: center;
  }
  .header-icon {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 44px;
    height: 44px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .modal {
    position: fixed;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    z-index: 1000000;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100vw;
    height: 100vh;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .modal__share-link {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-right: auto;
    margin-left: auto;
    padding: 16px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #fff;
  }
  .share-link--copied {
    display: none;
    color: #fff;
  }
}

@media screen and (max-width: 479px) {
  .main {
    padding: 0px;
  }
  .procurement-search {
    margin-top: 16px;
    margin-bottom: 16px;
    padding: 30px 16px;
  }
  .page-title {
    font-size: 24px;
  }
  .row-content__item {
    justify-items: start;
    grid-row-gap: 4px;
    grid-template-areas: "Input" "Value";
    -ms-grid-columns: 180fr;
    grid-template-columns: 180fr;
    -ms-grid-rows: auto 4px auto;
    grid-template-rows: auto auto;
  }
  .filter__showing {
    width: 100%;
    margin-right: 0px;
  }
  .filter__download {
    width: 100%;
  }
  .filter-details__inner {
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    justify-items: start;
    grid-row-gap: 10px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }
  .filter__share-link {
    width: 100%;
  }
}

#w-node-55d7111addee-5f5c5613 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-0d04597cc2af-5f5c5613 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-e5d5bc64978e-5f5c5613 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-6e9308cbe2f5-5f5c5613 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-04b10d29cfee-5f5c5613 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-5e528a36583a-5f5c5613 {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  grid-area: table-column-1;
}

#w-node-9837c765a781-5f5c5613 {
  -ms-grid-row: 1;
  -ms-grid-column: 3;
  grid-area: table-column-2;
}

#w-node-97425daed04d-5f5c5613 {
  -ms-grid-row: 1;
  -ms-grid-column: 5;
  grid-area: table-column-3;
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-ceef27c6b1e0-5f5c5613 {
  -ms-grid-row: 1;
  -ms-grid-column: 3;
  grid-area: table-column-2;
}

#w-node-ceef27c6b1e2-5f5c5613 {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  grid-area: table-column-1;
}

#w-node-ceef27c6b1e4-5f5c5613 {
  -ms-grid-row: 1;
  -ms-grid-column: 5;
  grid-area: table-column-3;
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-ceef27c6b1ed-5f5c5613 {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  grid-area: Input;
}

#w-node-ceef27c6b1ef-5f5c5613 {
  -ms-grid-row: 1;
  -ms-grid-column: 3;
  grid-area: Value;
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-9a3b4dcf69ad-5f5c5613 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-5498746fabb0-5f5c5613 {
  -ms-grid-row: 1;
  -ms-grid-column: 3;
  grid-area: table-column-2;
}

#w-node-5498746fabb2-5f5c5613 {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  grid-area: table-column-1;
}

#w-node-5498746fabb4-5f5c5613 {
  -ms-grid-row: 1;
  -ms-grid-column: 5;
  grid-area: table-column-3;
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-5498746fabbd-5f5c5613 {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  grid-area: Input;
}

#w-node-5498746fabbf-5f5c5613 {
  -ms-grid-row: 1;
  -ms-grid-column: 3;
  grid-area: Value;
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-f5c3ba6f95ce-5f5c5613 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-790112080747-5f5c5613 {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  grid-area: table-column-1;
}

#w-node-790112080749-5f5c5613 {
  -ms-grid-row: 1;
  -ms-grid-column: 3;
  grid-area: table-column-2;
}

#w-node-79011208074b-5f5c5613 {
  -ms-grid-row: 1;
  -ms-grid-column: 5;
  grid-area: table-column-3;
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-3cbdcba67fb3-5f5c5613 {
  -ms-grid-row: 1;
  -ms-grid-column: 3;
  grid-area: table-column-2;
}

#w-node-3cbdcba67fb5-5f5c5613 {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  grid-area: table-column-1;
}

#w-node-3cbdcba67fb7-5f5c5613 {
  -ms-grid-row: 1;
  -ms-grid-column: 5;
  grid-area: table-column-3;
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-3cbdcba67fc0-5f5c5613 {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  grid-area: Input;
}

#w-node-3cbdcba67fc2-5f5c5613 {
  -ms-grid-row: 1;
  -ms-grid-column: 3;
  grid-area: Value;
  -ms-grid-column-align: end;
  justify-self: end;
}

@media screen and (max-width: 479px) {
  #w-node-ceef27c6b1ed-5f5c5613 {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
  }
  #w-node-ceef27c6b1ef-5f5c5613 {
    -ms-grid-row: 3;
    -ms-grid-column: 1;
  }
  #w-node-5498746fabbd-5f5c5613 {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
  }
  #w-node-5498746fabbf-5f5c5613 {
    -ms-grid-row: 3;
    -ms-grid-column: 1;
  }
  #w-node-3cbdcba67fc0-5f5c5613 {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
  }
  #w-node-3cbdcba67fc2-5f5c5613 {
    -ms-grid-row: 3;
    -ms-grid-column: 1;
  }
}

@media screen and (max-width: 991px) {
  #w-node-b2019d0617ea-5f5c5613 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }
  #w-node-95389d1aec41-5f5c5613 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }
  #w-node-55d7111addee-5f5c5613 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }
  #w-node-0d04597cc2af-5f5c5613 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }
  #w-node-e5d5bc64978e-5f5c5613 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }
  #w-node-e9d16ff1b1ae-5f5c5613 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }
  #w-node-1b0cfee6eaea-5f5c5613 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }
  #w-node-6e9308cbe2f5-5f5c5613 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-column-align: end;
    justify-self: end;
  }
}

@media screen and (max-width: 767px) {
  #w-node-d03dd23a3bf0-5f5c5613 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }
  #w-node-006132fe240d-5f5c5613 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }
  #w-node-9a3b4dcf69ad-5f5c5613 {
    -ms-grid-column-align: center;
    justify-self: center;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 3;
    grid-column-start: span 3;
    -ms-grid-column-span: 3;
    grid-column-end: span 3;
  }
  #w-node-5307d05e7e4a-5f5c5613 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 3;
    grid-column-start: span 3;
    -ms-grid-column-span: 3;
    grid-column-end: span 3;
  }
  #w-node-f5c3ba6f95da-5f5c5613 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }
  #w-node-f5c3ba6f95ec-5f5c5613 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }
}

@media screen and (max-width: 479px) {
  #w-node-d03dd23a3bf0-5f5c5613 {
    -ms-grid-column-align: start;
    justify-self: start;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }
  #w-node-f5c3ba6f95da-5f5c5613 {
    -ms-grid-column-align: start;
    justify-self: start;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }
}

@font-face {
  font-family: 'Fake receipt';
  src: url('../fonts/fake-receipt.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}